(function(global) {

    global.appBootstrap = function() {
        Pace.stop();
        document.body.classList.remove('pace-running');
    }

    global.paceOptions = {
        document: true,
        eventLag: true,
        restartOnPushState: false,
        restartOnRequestAfter: false,
        ajax: false
    };

})(window);